$peragraph-size: 17px;
$heading-top: 40px;

@media screen and (min-width: 1200px) and (max-width: 1340px) {
	.battiti-text {
		max-width: 60px;
		text-align: left;
	}

}

@media screen and (min-width: 320px) and (max-width: 1699px) {

	/*.Topspace{
	 p{
		font-size: $peragraph-size!important;
	}
	}*/


	/*.r-indice {
		font-size: 14px!important;
		}		p{
	
	}*/
}

@media (min-width: 1200px) {
	.navi-part {
		margin-left: 70px;
	}
	.radar-chart .apexcharts-legend{
		transform: translate(100px, -150px)!important;
	   justify-content: left!important;
	   align-items: self-start!important;
	   display: block!important;
	  }
	  
	  .radar-chart .apexcharts-legend-series{
	  margin-bottom: 12px!important;
	  }
	  
	  .radar-chart .apexcharts-legend-text{
	  color: var(--NERO, #272848);
	  font-family: Lexend, sans-serif!important;
	  font-size: 16px!important;
	  font-style: normal;
	  font-weight: 700!important;
	  line-height: normal;
	  text-transform: uppercase;
	  }
	  
	  .radar-chart .apexcharts-graphical{
		transform: translate(10px, 40px)!important;
	  }
	
} 

@media screen and (min-width: 992px) and (max-width: 1199px) {
	.cmd-back-right{
		padding: 13px 15px!important;
	}
	.cmd-back-right-text{
		margin-bottom: 0!important;
	}
	.li-heading {
		font-size: 8px !important;
		text-align: center;
	}

	.legenda-text {
		line-height: 24px;
		min-height: 40px;

		span {
			display: none;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {

	.d-space-ipad{
		padding-right: 0px;
	}
	.btn-apr.col-3{
		max-width: fit-content;
		padding: 12px 7px;
	}
	.cmd-back-right {
		padding: 10px 10px!important;
	}
	.cmd-back-right-text{
		font-size: 10.5px;
		padding-left: 0;
		margin: 0;
		text-align: left!important;
	}
	.btn-inizia-ipad{
		padding: 12px 7px!important;
	}
	.ali-bottom {
		width: 100%;
		justify-content: center;
	}

	.auth {
		width: 90%;
	}

	.li-heading {
		font-size: 8px !important;
		text-align: center;
	}

	.legenda-text {
		line-height: 23px !important;

		span {
			display: none;
		}
	}
}

@media (max-width: 767px) {

	.bunono-w50{
		width: 50%;
	}
	.second-page-heading{
		h2{
		font-size: 30px;}
	}
	.bg-image {
		height: 100%;
		display: block;
	}

	.mb80 {
		margin-bottom: 20px;
	}

	.heading-login {
		h2 {
			font-size: 1.5rem;
		}

		margin-top: 25px;

		p {
			font-size: 0.9rem;
			line-height: 20px;
		}
	}

	.registrati-form {
		max-width: 100%;
	}

	.logo {
		position: inherit;
		padding-top: 5px;
		padding-bottom: 25px;
	}

	.bottom {
		position: inherit;
		padding-top: 13px;
		color: #272848 !important;
	}

	.mb-50 {
		margin-bottom: 15px;
	}

	.buttons {
		padding: 10px 20px;
		width: auto;
		font-size: 1rem;
	}

	.auth {
		width: 93%;
		padding: 30px;
	}

	.inautho {
		input {
			margin: 4px;
			width: 45px;
			height: 45px;
		}

		padding: 7px;
	}

	.icon-aut {
		p {
			font-size: 0.9rem;
		}

		.key {
			width: 60px;
		}
	}

	.right-dashboard {
		padding-left: 15px;
	}

	.align-j {
		display: inline-block;

		h4 {
			margin-bottom: 15px;
		}
	}

	.model-pop {
		.b-rigti {
			display: none;
		}
	}

}

@media (min-width: 576px) {

	.mx-w630 {
		.modal-dialog {
			max-width: 630px;
		}
	}

	.modal-dialog {
		max-width: 760px;
		// display: flex;
		justify-content: center;
		align-items: center;
		height: -webkit-fill-available;

	}

	.learning-popup-size {
		.modal-dialog.modal-sm {
			max-width: 489.336px;
			top: 194px;
		}
	}
}