$purple: #6d71f9;

.admin-bgimage {
  background-image: url("../../Image/admin-login.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: inherit;
}

.bgyellowadmin {
  background-image: url("../../Image/ADMIN_login_passdimenticata.png");
  background-size: cover;
}

.admin-autenticazione {
  background-image: url("../../Image/Rectangle233.png");
}

.adminbgsteps1 {
  background-image: url("../../Image/ADMIN_onboarding_01.png");
}

.adminbgsteps2 {
  background-image: url("../../Image/ADMIN_onboarding_02.png");
}

.adminbgsteps3 {
  background-image: url("../../Image/adminbg-steps3.svg");
}

.adminbgsteps4 {
  background-image: url("../../Image/ADMIN_onboarding_04.png");
}

.laststeps {
  background-image: url("../../Image/ADMIN_consenso.png");
}

.admin_otpbg {
  background-image: url("../../Image/bgotpadmin.png");
}

.admin-loginbg {
  background-image: url("../../Image/admin-loginbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  width: 100%;
  min-height: 100vh;
}


.benessere {
  margin-top: 48px;
  margin-bottom: 50px;
  display: inline-block;

  .p-lti {
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  .buono-dark {
    background-color: $purple;
    padding: 25px;
    border-radius: 7.81106px;
    width: 100%;
    height: 100%;

    h3 {
      font-size: 21.87px;
      font-weight: 700;
      color: #fff;
    }

    h2 {
      font-size: 187.79px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .numbertable {
      display: flex;
      justify-content: space-around;
      color: #fff;
      border-top: 1px solid #fff;
      padding-top: 44px;
      width: 100%;

      .numvb {
        span {
          font-size: 16px;
          font-weight: 300;
        }

        p {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }

  .ds-align {
    display: flex;
    align-items: center;

    img {
      padding-right: 30px;
    }
  }

  .gr-parts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .repo-dash {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .ri-dasgraph {
    background-color: #fff;
    padding: 20px;
    border-radius: 7.81106px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sistolica-l {
  display: flex;
  width: 100%;
}

.r105 {
  padding-right: 100px;
}

.bg-white-d {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
}

.topreport {
  margin-top: 48px;
  margin-bottom: 50px;

  .rigds {
    padding: 20px;
  }
}

.adrepl {
  margin-top: 50px;
}

.s86 {
  p {
    font-size: 63px !important;
    margin-left: 15px;
  }
}

.ma-badi {
  margin-bottom: 20px;
  width: fit-content;
  float: right;
  margin-top: 100px;
}

.profile-right {
  min-height: 100vh;
}

.admin-termscnd {
  background-image: url('../../Image/Group1399104.png');
}

.admin-privacy {
  background-image: url('../../Image/Group139904.png');
}

.image-privacy {
  background-image: url('../../Image/UTENTE_privacypolicy.png');
}

.align-between {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .top-pud {
    margin-top: 20px;
  }
}

.w-70 {
  width: 50%;
  position: absolute;
  bottom: 36px;
  right: 10px;
}

.h100 {
  min-height: 100vh;
}

.media-u {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;

  p {
    font-size: 105px;
  }

  .infor-cir-report {
    display: flex;
    justify-content: space-between;
  }

  .repomon {
    display: flex;
    justify-content: space-around;
  }

  .rep-num {
    margin-top: 30px;

    img {
      width: 24px;
      height: 27px;
    }

    span {
      font-size: 22px;
      font-weight: 300;
    }

    p {
      font-size: 28px;
      font-weight: 700;
      color: $purple;
      margin-bottom: 0px;
    }

  }
}

.Generazione {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.ipad-h-show {
  display: none;
}

.fl-m {
  display: flex;
}

.pad-in .adminbg-c-rig {
  &::before {
    background: url('../../Image/third-step-icon.svg');
    background-repeat: no-repeat;
    background-size: 83%;
    background-position: left;
  }
}

.admin-faqs {
  background-image: url('../../Image/UTENTE_FAQ.png');
  background-size: 100% 100%;
}

.dark {
  .in-f {
    height: auto;
  }
}

.new-admin-r {
  background-color: #fff;
  box-shadow: 0px 4px 16px rgba(234, 238, 255, 0.6);
  border-radius: 5px;
  padding: 15px;
  text-align: left;

  h4 {
    font-size: 15.34px;
    color: #272848;
    font-weight: 700;
    display: inline-block;
    line-height: 19.18px;
  }
}

.box-rr-ght {
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  height: 100%;
}

.intervento {
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin-bottom: 50px;
  padding-top: 5px;

  img {
    margin-right: 5px;
  }
}

.redline {
  width: 30px;
  height: 5px;
  background: #B02418;
  display: block;
  margin-right: 24px;
}

.l-height0 {
  text-align: left;
  margin-bottom: 60px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.yellow_lines {
  background: #F6BF42;
}

.light-line {
  background: #F9EF96;
}


@media (min-width: 1920px) {
  .admin-bgimage {
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center center;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .dash50 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .r105 {
    padding-right: 60px;
  }

  .tabicol2 {
    max-width: 100%;
    margin-top: 20px;
  }

  .name_left {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .bgyellowadmin {
    background-size: 100% 100%;
  }

  .bgyellowadmin {
    background-size: 100% 100%;
  }

  .admin_otpbg {
    background-size: 100% 100%;
  }

  .adminbgsteps2 {
    background-image: url("../../Image/Group1342.png");
  }

  .loginipad-bg {
    background-image: url("../../Image/admin-ipad.png") !important;
  }

  .hide-iipad {
    display: none;
  }

  .ipad-h-show {
    display: block;
  }

  .w40-equal {
    flex: 0 0 40%;
    max-width: 40%;
    margin-bottom: 20px;
  }

  .w80-equal {
    flex: 0 0 60%;
    max-width: 60%;
    margin-bottom: 20px;
  }

  .benessere {
    .buono-dark {
      height: auto;
      margin-bottom: 20px;
      padding: 25px 0px;

      h2 {
        float: left;
        width: 50%;
        line-height: 180px;
        border-right: 1px solid #fff;
      }

      .numbertable {
        width: 50%;
        float: left;
        border-top: 1px solid transparent;
      }
    }

    .ds-align {
      h3 {
        text-transform: uppercase;
      }

      img {
        padding-right: 30px;
        padding-left: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .admin-loginbg {
    background: transparent;
  }
  
  .content-center-mob{
    justify-content: center !important;
    margin-left: -7px;
    >div{
      margin-right: 0px;
    }
  }
  .buttons-superadmin{
    flex-wrap: wrap;
  }
  .report-mob {
    .nav-item {
      padding: 5px 2px;
      margin: 0px 15px 0 0;
      border-radius: 5px;
      min-width: fit-content;
      text-align: center;
    }

    .nav-link {
      text-align: center;
      padding: 10px !important;
      margin-bottom: 0 !important;
      margin-right: 0px !important;

      span {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .nav-tabs .nav-item .nav-link {
      background-color: #cdd0fd;
      border-radius: 5px;
    }

    .nav-tabs .nav-item .nav-link.active {
      background-color: $purple;
      opacity: 1;
      border: none;
      width: 100%;
      text-align: center;
    }

  }

  .topreport {
    display: none !important;
  }

  .ma-badi {
    float: left;
    margin-top: 50px;
  }

  .autenticazione1 {
    background-image: url('../../Image/newadmin-login.png') !important;
  }

  .admin-login {
    background-image: url('../../Image/newadmin-login.png') !important;
  }

  .bgyellowadmin {
    background: transparent;
  }

  .adminbgsteps1 {
    background-image: url('../../Image/Group1190.png');
  }

  .adminbgsteps2 {
    background-image: url('../../Image/Group1194.png');
  }

  .adminbgsteps3 {
    background-image: url('../../Image/Group11940.png');
  }

  .adminbgsteps4 {
    background-image: url('../../Image/Group1195.png');
  }

  .bgadminmob {
    background-image: url('../../Image/newadmin-bg.png') !important;
    background-size: cover;
    min-height: 100vh;
    background-position: 100%;
  }

  .bgsteps4mob {
    background-image: url('../../Image/Group1195.png') !important;
  }
}

.content-section {
  .content-section-button {
    border-bottom: 1px solid #bcbdff;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2px;

    button {
      font-size: 18px;
      background: none;
      opacity: 0.5;
      color: #6d71f9;
    }

    .active {
      border-bottom: 3px solid #6d71f9;
      border-radius: 0px;
      margin-right: 30px;
      padding-left: 0px;
      cursor: pointer;
      font-weight: 600;
      opacity: 1;
    }
    .videoActive {
      border-bottom: 3px solid #6d71f9;
      border-radius: 0px;
      margin-right: 30px;
      padding-left: 6px;
      margin-left:10px;
      cursor: pointer;
      font-weight: 600;
      opacity: 1;
    }

  }
}

.static-filter {
  display: flex;

  .program,
  .course,
  .date-range-input {
    width: 180px;
    margin-left: 15px;
    background-color: #e0e0fd;
    padding: 4px 6px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../../../../assets/Image/VectorDown.svg');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: 1em auto, 100%;
  }
}

.react-datepicker {
  font-family: Lexend, sans-serif;
  border: none !important;
  .react-datepicker__triangle {
    &::after {
      all: unset !important;
    }

    &::before {
      all: unset !important;
    }
  }
  .react-datepicker__header{
    background-color: white;
    border-bottom: none;
  }
  .react-datepicker__day-name{
    color: #6d71f9;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 10px;
    margin: 3px 0px;
  }
  .react-datepicker__day--in-range{
    background-color: #d3d4fd;
    color:black;
  }
  .react-datepicker__day--outside-month {
    color: rgb(185, 183, 183) !important;
    pointer-events: none;
    background-color: transparent;
  }
  .react-datepicker__day{
    width: 1.5rem;
    line-height: 1.5rem;
    font-size: 10px;
    margin: 3px 0px;
    border-radius: 0px;
  }
  .react-datepicker__day--range-start{
    background-color: #6d71f9 ;
    // background-color: #d3d4fd;
    position: relative;
    border-radius: 50%;
    z-index: 99;
    &::after{
      content: "";
      background-color: #6d71f9;
      top: 0;
      left: 50%;
      position: absolute;
      height: 1.5rem;
      width: 50%;
      z-index: -1;
    }
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px !important;
    }
  }
  .react-datepicker__day--range-end{
    background-color: #6d71f9;
    position: relative;
    border-radius: 50%;
    z-index: 99;
    &::before{
      content: "";
      background-color: #6d71f9;
      top: 0;
      left: 0;
      position: absolute;
      height: 1.5rem;
      width: 35%;
      z-index: -1;
    }
  }
}
.react-datepicker-popper{
  padding-top: 4px !important;
  inset: 0px auto auto 13px !important;
}
.date-picker-header {
  margin: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  div{
    font-weight :600;
    font-size: 14px ;
    line-height: 15px;
  }
  button{
    background: none;
  }
}


.react-datepicker__week{
  // background: #d3d4fd;
  // display: inline-flex;
  // margin-bottom: 5px;
  // border-radius: 15px;
}

.datepicker__day--in-range:hover{
  background-color: #d3d4fd!important;
}

.react-datepicker__day--keyboard-selected:hover{
  background-color: #d3d4fd!important;
}
.container-new-admin{
  margin: 0px 15px;
}

.dark .text-color-wh
{
    color: #fff;  
}  