$purple: #6d71f9;
.admin-bgimage {
  background-image: url("../../Image/admin-login.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: inherit;
}

// .bgyellowadmin {
//   background-image: url("../../Image/ADMIN_login_passdimenticata.png");
// }

.bgloginsuper-admin{
  background-image: url("../../Image/super-admin-loginbg.svg");
  background-size: cover;
  background-position: 100% 100%;
}

.super-admin{
  input{
  background-color: #EBF0FF;
  }
  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #d7dced;
    opacity: 1; /* Firefox */
  }
  
  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:#d7dced;
  }
  
  input::-ms-input-placeholder { /* Microsoft Edge */
    color: #d7dced;
  }
}

.admin-autenticazione {
  background-image: url("../../Image/Rectangle233.png");
}

.adminbgsteps1 {
  background-image: url("../../Image/ADMIN_onboarding_01.png");
}

.adminbgsteps2 {
  background-image: url("../../Image/ADMIN_onboarding_02.png");
}

.adminbgsteps3 {
  background-image: url("../../Image/adminbg-steps3.svg");
}

.adminbgsteps4 {
  background-image: url("../../Image/ADMIN_onboarding_04.png");
}

.laststeps {
  background-image: url("../../Image/ADMIN_consenso.png");
}
.admin_otpbg{
  background-image: url("../../Image/bgotpadmin.png");
}

.admin-loginbg{
background-image: url("../../Image/admin-loginbg.png");
background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
    width: 100%;
    min-height: 100vh;
}


.benessere {
    margin-top: 48px;
    margin-bottom: 50px;
    display: inline-block;
  .p-lti {
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  .buono-dark {
    background-color: $purple;
    padding: 25px;
    border-radius: 7.81106px;
    width: 100%;
    height: 100%;

    h3 {
      font-size: 21.87px;
      font-weight: 700;
      color: #fff;
    }
    h2 {
      font-size: 187.79px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .numbertable {
      display: flex;
      justify-content: space-around;
      color: #fff;
      border-top: 1px solid #fff;
      padding-top: 44px;
      width: 100%;
      .numvb {
        span {
          font-size: 16px;
          font-weight: 300;
        }
        p {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
  .ds-align {
    display: flex;
    align-items: center;
    img {
      padding-right: 30px;
    }
  }
  .gr-parts{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .repo-dash{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .ri-dasgraph{
    background-color: #fff;
    padding: 20px;
    border-radius: 7.81106px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sistolica-l{
display: flex;
width: 100%;
}

.r105{
padding-right: 100px;
}

.bg-white-d{
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
}

.topreport{
  margin-top: 48px;
  margin-bottom: 50px;
  .rigds{
   padding: 20px;
  }
}

.adrepl{
  margin-top: 50px;
}

.s86{
  p{
    font-size: 63px!important;
    margin-left: 15px;
  }
}

.ma-badi{
  margin-bottom: 20px;
  width: fit-content;
  float: right;
  margin-top: 100px;
}

.profile-right{
min-height: 100vh;
}

.admin-termscnd{
  background-image: url('../../Image/Group1399104.png');
}
.admin-privacy{
  background-image: url('../../Image/Group139904.png');
}
.image-privacy{
  background-image: url('../../Image/UTENTE_privacypolicy.png');
  }

.align-between{
display: flex;
justify-content: space-between;
align-items: center;
.top-pud{
  margin-top: 20px;
}
}

.w-70{
  width: 50%;
  position: absolute;
  bottom: 36px;
  right: 10px;
}

.h100{
  min-height: 100vh;
}

.media-u{
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  p{
    font-size: 105px;
  }
.infor-cir-report{
  display: flex;
  justify-content: space-between; 
 }
 .repomon{display: flex;
  justify-content: space-around;
}
.rep-num{
  margin-top: 30px;
  img{
    width: 24px;
    height: 27px;
  }
  span{
    font-size: 22px;
    font-weight: 300;
  }
  p{
    font-size: 28px;
    font-weight: 700;
    color: $purple;
    margin-bottom: 0px;
  }

}
}

.Generazione {
  font-size: 12px!important;
  font-weight: 400!important;
}

 .ipad-h-show{
  display: none;
  }

  .fl-m{
    display: flex;
  }

  .pad-in .adminbg-c-rig{
    &::before{
    background: url('../../Image/third-step-icon.svg');
    background-repeat: no-repeat;
    background-size: 83%;
    background-position: left;
   }
} 

  .admin-faqs{
    background-image: url('../../Image/UTENTE_FAQ.png');
    background-size: 100% 100%;
  }

  .dark  {
    .in-f{
      height: auto;
    }
}

.new-admin-r{
  background-color: #fff;
  box-shadow: 0px 4px 16px rgba(234, 238, 255, 0.6);
  border-radius: 5px;
  padding: 15px;
  text-align: left;
  h4{
    font-size: 15.34px;
    color: #272848;
    font-weight: 700;
    display: inline-block;
    line-height: 19.18px;
  }
}

.box-rr-ght{
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  height: 100%;
}

.intervento{
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin-bottom: 50px;
  padding-top: 5px;
  img{
    margin-right:5px;
  }
}

.redline {
  width: 30px;
  height: 5px;
  background: #B02418;
  display: block;
  margin-right: 24px;
}

.l-height0{
  text-align: left;
  margin-bottom: 60px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.yellow_lines{
  background:#F6BF42;
}
.light-line{
  background:#F9EF96;
}


  @media (min-width: 1920px) {
    .admin-bgimage{ 
      // background-size: cover;
      // background-repeat: no-repeat;
      // background-position: center center;
   }
  }
  
  @media screen and (min-width: 992px) and (max-width: 1199px){
    .dash50{
      flex: 0 0 50%;
      max-width: 50%;
    }
    .r105 {
      padding-right: 60px;
  }
  .tabicol2 {
    max-width: 100%;
    margin-top: 20px;
   }
   .name_left{
    font-size: 12px;
   }
  }
  
  @media screen and (min-width: 768px) and (max-width: 991px){
    // .modal-content {
    //   margin-left: 50px;
    //   }
    .bgyellowadmin{
      background-size: 100% 100%;
    }


    .admin_otpbg{
      background-size: 100% 100%;
    }
    .adminbgsteps2{
      background-image: url("../../Image/Group1342.png");
  }
.loginipad-bg{
  background-image: url("../../Image/admin-ipad.png")!important;
}
    .hide-iipad{
      display: none;
    }
    .ipad-h-show{
    display: block;
    }
    .w40-equal{
      flex: 0 0 40%;
      max-width: 40%;
      margin-bottom: 20px;
    }
    .w80-equal{
      flex: 0 0 60%;
      max-width: 60%;
      margin-bottom: 20px;
    }
    .benessere {
      .buono-dark{
        height: auto;
        margin-bottom: 20px;
        padding: 25px 0px;
         h2{
         float: left;
         width: 50%;
         line-height: 180px;
         border-right: 1px solid #fff;
        }
      .numbertable{
        width: 50%;
        float: left;
        border-top: 1px solid transparent;
      }
    } 
     .ds-align {
      h3{text-transform: uppercase;}
      img{
      padding-right: 30px;
      padding-left: 20px;
      }
     }
   }
}

@media (max-width: 767px){
  .admin-loginbg{
    background: transparent;
  }
  .report-mob{
    .nav-item{
    padding: 5px 2px;
    margin: 0px 15px 0 0;
    border-radius: 5px;
    min-width: fit-content;
    text-align: center;
    }
    .nav-link{
      text-align: center;
      padding: 10px!important;
      margin-bottom: 0!important;
      margin-right: 0px!important;
      span{
      color: #fff;
      font-size: 14px;
      font-weight: 700;
     }
    }
    .nav-tabs .nav-item .nav-link{
      background-color: #cdd0fd;
      border-radius: 5px;
    }
    .nav-tabs .nav-item .nav-link.active{
      background-color: $purple;
      opacity: 1;
      border: none;
      width: 100%;
      text-align: center;
    }
   
  }
  .topreport{
    display: none!important;
  }
  .ma-badi{
    float: left;
    margin-top: 50px;
  }
  .autenticazione1{
    background-image: url('../../Image/newadmin-login.png')!important;
  }
  .admin-login{
    background-image: url('../../Image/newadmin-login.png')!important;
  }
  .bgyellowadmin{
    background: transparent;
  }
  .adminbgsteps1{
    background-image: url('../../Image/Group1190.png');
  }
  .adminbgsteps2{
    background-image: url('../../Image/Group1194.png');
  }
  .adminbgsteps3{
    background-image: url('../../Image/Group11940.png');
  }
  .adminbgsteps4{
    background-image: url('../../Image/Group1195.png');
  }
  .bgadminmob{
    background-image: url('../../Image/newadmin-bg.png')!important; 
    background-size: cover;
    min-height: 100vh;
    background-position: 100%;
   }

   .bgsteps4mob{
    background-image: url('../../Image/Group1195.png')!important;
   }
}

.form-control-input2
{
  width: 97%;
  margin-top: 7px;
  margin-right: 5px;
  margin-left: 5px;
}
.RightNextICON
{
  padding-top:21px!important;
}



.switch-field {
	display: flex;
	margin-bottom: 0px;
	overflow: hidden;
}

.switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.switch-field label {
	background-color: #e4e4e4;
	color: rgba(0, 0, 0, 0.6);
	font-size: 14px;
	line-height: 1;
	text-align: center;
	padding: 8px 16px;
	margin-right: -1px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
	transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #6d71f9;
  box-shadow: none;
  color: white;
}

.switch-field label:first-of-type {
	border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
	border-radius: 0 4px 4px 0;
}

.dark .text-color-wh
{
    color: #fff;  
} 
 
.dark .brainwellness{
  color: #fff;
}

.react-datepicker__navigation:hover *::before {
  border-color: #6d71f9;
}
.react-datepicker__navigation-icon--next::before {
  left: 10px;
}

.disable-btn-text
{
  background-color: rgba(204, 204, 204, 0.8);
    height: 64px;
    padding: 0px 60px 0px 60px;
    font-size: 18px;
    border-radius: 5px;
    color: #fff !important;
    letter-spacing: 0.5px;
    font-weight: 500;
    cursor: none;
}
.user-log-text
{
  display: block;
  float: left;
}

