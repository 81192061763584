.user-training-section {
    position: relative;
    z-index: 0;
}

.search-icon-user-material {
    position: absolute;
    top: 19px;
    left: 13px;
}

.cursor-pointer {
    cursor: pointer;
}

.test-questions {
    .question {
        margin: 10px 0px;

        .name,
        .span {
            color: #272848;
            font-family: Lexend,sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
                font-weight: 700;
            }
        }

        .answer-textarea {
            textarea {
                width: 100%;
                background-color: #cdd0fd;
                border-radius: 5px;
                border: none;
                padding: 10px;
            }

        }

        .document-program-details-new {
            margin: 5px 0px;
        }

        .answer-selected {
            background-color: #6d71f9;
        }

        .true-false-button {
            button {
                width: 80px;
                padding: 10px 0px;
                height: auto;
                background-color: #cdd0fd;
                box-shadow: none;
            }

            .selected {
                background-color: #6d71f9 !important;
            }
        }
    }

}


.outer-container {
    text-align: center;
    /* Horizontal centering */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Vertical centering */
}

.inner-container {
    display: flex;
    align-items: center;
    /* Vertical centering for inner elements */
}

.inner-container {
    img {
        margin-bottom: 39px;
    }

    .box-wraping {
        width: 446px;
    }

    h3 {
        color: var(--PRIMARIO_VIOLA, #6D71F9);
        text-align: center;
        font-family: Lexend,sans-serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 25px;
    }

    p {
        color: var(--NERO, #272848);
        font-family: Lexend,sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: bold !important;
        line-height: normal;
    }
}

.contact-heading a {
    color: #6D71F9;
    font-size: 14px;
    /* text-transform: uppercase; */
    margin: 5px;
    display: block;
}

.btn-anxiety-align {}

.dashboard-tabs {
    margin-top: 23px;
}

.dashboard-tabs .nav-tabs {
    margin-bottom: 10px !important;

}

.dashboard-tabs .nav-item .nav-link.active.show {
    background-color: transparent;
    border-radius: 0;
    /* Set it to 0 to remove border radius */
    border: none;
    border-bottom: 3px solid #6d71f9;
}

.wellness {
    background-color: #fff;
    border-radius: 5px;

    p {
        font-size: 65px !important;
        text-align: center;
        margin-inline: auto;
    }
}

.dark .wellness {
    background-color: #272848;
    border-radius: 5px;
    color: white;

    p {
        font-size: 65px !important;
        text-align: center;
        margin-inline: auto;
    }
}

.brain-wellnes-heading {
    font-family: Lexend,sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #272848;
    width: 100%;
}

.dark .misurazione {
    border-radius: 5px;
    background-color: #272848;
    color: white;
    padding: 15px;
    width: 95%;
    height: 927px;
    position: relative;

    .d-flex-misurazione {
        display: flex;
        justify-content: space-between;

        h3 {
            color: white;
            font-family: Lexend,sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
        }

        a {
            color: white;
            font-family: Lexend,sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            text-transform: uppercase;
        }
    }

    .assiduo {
        h4 {
            color: white;
            text-align: center;
            font-family: Lexend,sans-serif;
            font-size: 8px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: uppercase;
            margin-bottom: 2px;
        }

        p {
            color: white;
            font-family: Lexend,sans-serif;
            font-size: 6px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: uppercase;
        }
    }
}
.underline-a{
    color: #6D71F9;
    color: var(--PRIMARIO_VIOLA, #6D71F9);
    font-family: Lexend, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    text-transform: uppercase;
}
.misurazione {
    border-radius: 5px;
    background: var(--BIANCO, #FFF);
    padding: 15px;
    width: 95%;
    height: auto;
    position: relative;
    margin: 0 auto;
    .d-flex-misurazione {
        display: flex;
        justify-content: space-between;

        h3 {
            color: var(--NERO, #272848);
            font-family: Lexend,sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
        }

        a {
            color: var(--PRIMARIO_VIOLA, #6D71F9);
            font-family: Lexend,sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            text-transform: uppercase;
        }
    }

    .assiduo {
        h4 {
            color: var(--NERO, #272848);
            text-align: center;
            font-family: Lexend,sans-serif;
            font-size: 8px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: uppercase;
            margin-bottom: 2px;
        }

        p {
            color:rgb(39 40 72);
            font-family: Lexend,sans-serif;
            font-size: 6px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: uppercase;
        }
    }
}

.brain-wellnes-heading img {
    width: 16px;
    height: 26px;
    flex-shrink: 0;
}

.training_cognitivo img {
    width: 18.399px;
    height: 20.203px;
    flex-shrink: 0;
}

.brain-wellnes-heading span {
    margin-left: 5px
}

.dark .brain-wellnes-heading {
    color: white;

    span {
        color: white;
    }
}

.training_cognitivo span {
    margin-left: 5px;
    color: var(--NERO, #272848);
    font-family: Lexend,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.GONOGO_ICONA img {
    width: 16.517px;
    height: 16.701px;
    flex-shrink: 0;
}

.GONOGO_ICONA span {
    color: var(--NERO, #272848);
    font-family: Lexend,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dark .GONOGO_ICONA span {
    color: white;
}

.gonogo_right {
    width: 142px;
    height: 19px;
    flex-shrink: 0;
}

.bg-boxes {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    position: relative;
    width: 100%;
}

.wellness h4 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    text-align: left;
    display: flex;
    align-items: center;
}

.Fisiologici {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.gonogo-chart {
    width: 85.414px;
    height: 85.414px;
    flex-shrink: 0;
}

.dark .gonogo-text {
    color: #fff;
}

.gonogo-text {
    display: block;
    color: var(--NERO, #272848);

    .btn-anxiety-align {
        text-align: right;
    }

    .btn-anxiety {
        margin-left: 10px;
        line-height: 10px !important;
        height: 45px !important;
    }

    .heading-que {
        font-size: 48px !important;
    }

    .selected-answer {
        background-color: #6d71f9 !important;
        color: #fff !important;
    }

    .input-width-ans {
        width: 520px;
        color: var(--NERO, #272848);
        font-family: Lexend,sans-serif;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .program-text2 {
        margin-top: 64px;
    }

    .medaglia span {
        color: var(--NERO, #272848);
        font-family: Lexend,sans-serif;
        font-size: 8px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
        opacity: 0.3;
    }

    .no-image {
        height: 80px;
    }

    .top-right-score {
        border-radius: 7px;
        background: #6d71f9;
        padding: 0px 22px;
        display: flex;
        align-items: center;
        /* float: right; */
        position: absolute;
        right: 11px;
        top: 22px
    }


    // span.label {
    //    // background-color: #9ECB61 !important;
    //  //  background-color: #9ECB61 !important;
    //     width: 200px;
    //     height: 5px;
    //     margin-left: -281px;
    //     margin-top: 30px;

    // }






    .barCompleted {
        background-color: #9ECB61;
        margin-left: -17px;
        //  width:100%;

    }

    .meditatore-sidebar {
        margin-top: 95px;
    }

    .label {
        color: black;
        background: #9ECB61 !important;
        height: 5px !important;
        border: none !important;
        width: 0% !important;
        font-size: 0px !important;
    }

    .wellness span.green.line {
        bottom: 10px !important;
        background: #9ECB61 !important;
    }

    .assiduo.assiduoMedal h4 {
        color: var(--NERO, #272848);
        text-align: center;
        font-family: Lexend,sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
    }

    .assiduo.assiduoMedal p {
        color: rgba(39, 40, 72, 0.30);
        font-family: Lexend,sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
    }

    .assiduoMedal-text {
        color: #272848;
        text-align: center;
        font-family: Lexend,sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 134.296%;
        /* 18.801px */
        padding-top: 15px;
    }

    .assiduoMedal-text2 {
        color: #272848;
        text-align: center;
        font-family: Lexend,sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 134.296%;
        /* 18.801px */
        margin-top: 100px;

        p {
            width: 262px;
            margin-left: 56px;
        }
    }

    .buttons.scopri {
        height: 50px !important;
    }

    .scopri {
        color: var(--BIANCO, #FFF);
        font-family: Lexend,sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }


    .meditatore-sidebar .bottom-span {
        position: absolute;
        /* Position the span absolutely */
        bottom: 0;
        /* Place the span at the bottom of the div */
        left: 0;
        /* Optional: You can adjust the left position if needed */
        padding: 5px;
        /* Optional: Add padding for better appearance */
        color: var(--PRIMARIO_VIOLA, #6D71F9);
        font-family: Lexend,sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-transform: uppercase;
        margin-top: 62px;
    }

    .medal-modal-body .medal-heading h3 {
        color: var(--NERO, #272848);
        font-family: Lexend,sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .assiduoMedalText {
        text-align: center !important;

        h4 {
            height: 4px;
            color: #272848;
            text-align: center;
            font-family: Lexend,sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 300;
            line-height: 134.296%;
            text-transform: uppercase;
        }
    }

    .assiduoMedalText p {
        height: 4px;
        color: #272848;
        text-align: center;
        font-family: Lexend,sans-serif;
        font-size: 10px !important;
        font-style: normal;
        font-weight: 300;
        line-height: 134.296%;
    }

    //   .stepper {
    //     .sc-gEvEer{
    //         width: 64.583px;
    //         height: 64.583px;
    //         flex-shrink: 0;
    //     }
    //     .dryqEs{
    //         margin: 0px;
    //     }
    //   }

    .medal-heading {
        margin-top: 95px;
    }



    .cfwewB {

        background: #6d71f9 !important;
        top: 90px !important;
        width: 100% !important;
        height: 2px !important;
        margin: 0 !important;
    }

    .cfwewB:after {
        content: "";

        background: none !important;

    }

    .hHnQes {
        top: 100px;
        position: relative;
        margin: 0px !important;
        height: 0 !important;

        &::before {
            content: '';
            position: absolute;
            width: 116px;
            height: 2px;
            background: #6d71f9;
            left: -40px;
            margin: 0;

        }

        &::after {
            top: -10px !important;
            left: 0 !important;
            width: 3px !important;
            height: 11px !important;
            border-radius: 2.375rem !important;
            background: #6d71f9 !important;
            margin-left: -16px !important;
        }

    }

    .dryqEs {
        top: 100px;
        background: transparent !important;
        background: #6265df !important;
        height: 1px !important;
    }

    .eXanma {

        min-width: auto !important;

    }

    .settimana {
        padding-top: 22px;
        color: var(--NERO, #272848);
        text-align: center;
        font-family: Lexend, sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
    }

    .iKVQQq {
        position: inherit !important;
    }

    .kowdNV {
        margin-top: 0px !important;
    }

    .iKVQQq {
        position: absolute !important;
        top: 100%;
        left: -27px !important;
        min-width: auto !important;
        margin-top: 1em;
    }

    .ciUHSk {
        margin-top: 0px !important;
    }

    .dryqEs {
        position: relative;
        margin: 0px !important;

        &::before {
            content: '';
            position: absolute;
            width: 145px;
            height: 2px;
            background: #6d71f9;
            left: -53px;
            margin: 0;

        }

        &::after {
            content: '';
            position: absolute;
            top: -10px !important;
            left: -14px !important;
            width: 3px !important;
            height: 11px !important;
            border-radius: 2.375rem !important;
            background: #6d71f9 !important;
        }
    }

    .ivOTC {
        background: #ffffff !important;
        color: #ffffff !important;
    }

    .jhYNEM {
        visibility: hidden !important;
    }

    .progress {
        overflow: visible !important;
        margin-top: 20px !important;

    }

    .progress .settimanaDesc {
        // top: -10px !important;
        // left: 0 !important;
        // width: 3px !important;
        // height: 11px !important;
        // border-radius: 2.375rem !important;
        // background: #6d71f9 !important;
        // position: relative;
        // width: 0;
        // height: 0;
        // border-left: 10px solid transparent;
        // border-right: 10px solid transparent;
        // border-top: 20px solid #6d71f9;
        // border-top-left-radius: 50%;
        // border-top-right-radius: 50%;
        position: relative;
        width: 0;
        height: 0;
        margin-top: -53px;
        border: 7px solid #6d71f9;
        border-radius: 7px 7px 7px 7px;
        margin-left: 19px;
    }

    .khKHqr {
        background: white !important;

    }

    .dSqjHD {
        font-size: 0px !important;
    }

    .kXLDMu {
        background: white !important;
    }

    .bottom-span-medal {
        position: absolute;
        bottom: 10px;
        left: 10px;

    }

    .buno-wrapper-4 {
        position: relative;
        top: -59px;
    }

    .buon4 {
        margin-bottom: 10px;
    }

    .last {
        .buon-completato {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                left: 24px;
                width: 3px;
                height: 11px;
                border-radius: 2.375rem;
                background: #6d71f9;
                bottom: -13px;
            }
        }
    }

    .video-text {
        text-align: start;

    }

    .depression-text-style {
        font-size: 14px;
        font-weight: 400;
        margin-left: 30px;
        text-align: left;
    }

    // .answerClick
    // {
    //     background-color: #6d71f9 !important;
    //     color : #fff !important;

}

.user-test-list {
    margin-top: 10px;

    .enable-btn {
        width: 180px !important;
        padding: 5px 10px !important;
    }

    .disable-btn2-new {
        background-color: green !important;
    }
}

.display-visible-tab {
    display: none;
}
.light .texttr{
    font-size: 10px; 
    color: #272848;
}
.dark .texttr{
    font-size: 10px;
    color: #eee;
}
@media screen and (max-width: 767px) {
    .contact-heading {
        // padding-left: 16px;
        position: static !important;
    }
    .mobile-btn-email {
        margin-top: 5px;
    }

    .program-back-btn {
        margin-left: -18px;
    }

    .small-dot {
        display: block;
    }

    .btn-anxiety {
        margin-bottom: 11px;
        min-width: 53%;
    }

    .btn-anxiety-align {
        text-align: center;
    }

    .que-font {
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 10px;
    }

    .program-font {
        font-size: 26px;
        font-weight: 700;
    }

    .wrong-test-heading {
        font-size: 28px !important;
    }

    .wrong-test-para {
        font-size: 15px;
        padding: 0px 40px;
    }

    .search-mobile-box {
        width: 100%;
    }

    .show-btn-space {
        line-height: 10px;
    }

    .searchmoved-top {
        margin-top: 0px !important;
        margin-bottom: 0 !important;
    }

    .protocolli-data .search-label {
        margin-bottom: 0px;
    }

    .document-section .description-document .icon-image>img {
        width: 100px;
        height: 100px;
    }

    .document-section .description-document .icon-image {
        margin: 0px 5px;
    }

    .display-visible-tab {
        display: block;
    }

    .display-none-tab {
        display: none;
    }
}

.dark .profile-drop .dropdown-menu {
    background-color: #393b68;
}

.dark .profile-drop .dropdown-item {
    color: #fff;

    &:hover {
        color: #272848;
    }
}

.dark .profile-drop .dropdown-item {
    &:hover img {
        filter: none;
    }
}

.dark .profile-drop .dropdown-item img {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(203deg) brightness(200%) contrast(200%);
}

.fitdark-text {
    display: flex;
    align-items: center;
}

.contact-heading {
    padding-left: 16px;
    position: fixed;
    bottom: 70px;
    text-transform: uppercase;
}

.course-content-list {

    .enable-btn,
    .disable-btn2-new {
        width: 100%;
        padding: 10px;
    }
}
@media screen and (min-width: 1199px) and (max-width: 1600px) {
    .contact-heading { position: static;}

}
@media screen and (min-width: 768px) and (max-width: 1199px) {
    .contact-heading {
        padding-left: 0;
        position: static;
    }

    .fitdark-text {
        display: block;
    }

    .bot-set {
        padding-bottom: 0px !important;
    }

    .contact-heading a {
        font-size: 10px;
        padding-bottom: 8px;
        margin: 0;
    }

}

.dark .depression-text-style {
    color: #fff;
}

.dark .text-color-wh {
    color: #fff;
}

.dark .text-color-black {
    color: #212529;
}

.text-formation {
    white-space: nowrap;
}

.dark {
    .ipd-righ {
        color: white;
    }
}

.dark .dashboard-tabs .nav-item {
    a {
        color: white !important;
    }

}

.dark {
    .dashboard-section {
        color: #fff;
    }

    .container-fluid {
        color: #fff;
    }

    .container-fluid-dark {
        color: #212529;
    }
}

.dark {
    .right-dashboard {
        color: #fff;
    }
}

.measure {
    color: #000 !important;
    text-align: left !important;
}

.dark {
    .measure {
        color: #fff !important;
        text-align: left !important;
    }
}

.dark {
    .que-font {
        color: #000 !important;
    }
}

.drop-down-menu-items{
    display: flex !important;
    justify-content: flex-end;
  
}
.bg-none{
    background:none;
    cursor: pointer;
}
.bg-none::after{
    display: none;
}

.switch-para{
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    input{
        accent-color: #6d71f9;
    }
}
.switch-check{
    width: 24px;
    height: 24px;
}

.dropdown-menu{
   min-width: 104px !important;
   padding-bottom: 0px !important;
    input[type=radio]{
        visibility: visible;
        min-width: 20px;
        min-height: 20px;
    }
}
.language-block{
    display: flex !important;
    justify-content: end !important;
}
.start-btn-cmd
{
    text-align: end;
}

.common-activity
{
    float: right;
    margin-top: 12px;
    color: var(--NERO, #272848);
    text-align: right;
    font-family: Lexend, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    margin-right: 5px;
    margin-bottom: 5px;
}
.free-activity-label-cmd{
    float: left;
}
.progress-bg-color-cmd
{
 background-color: #dddefb;
}


.free-activity-score {
    font-size: 60px;
    font-weight: 700
}

// .back-btn-text-color{
//     color: #000;
// }
.label-{
    display:none;
}
.assiduo{
    padding: 16px 0px 16px 0px;
}
.pointer .assiduo img{
    cursor: pointer;
}
.cognitive-training-text{
font-family: Lexend ,sans-serif;
font-size: 10px;
font-weight: 300;
line-height: 12.5px;
text-align: right;
}

.pera-datas{
    max-width: inherit !important;
    text-align: right;
    line-height: 45px;
}

.report-text-card
{
    float: left;
    margin-top: 12px;
    color: var(--NERO, #272848);
    // text-align: left;
    font-family: Lexend;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    // text-transform: uppercase;
    // margin-right: 5px;
    // margin-bottom: 5px;
}
.color-blue{
    color: #6d71f9;
    font-weight: 700;
}
.alert-custom-primary {
    color: #eee;
    background-color: #6d71f9;
    border-color: #b8daff;
}
.alert-custom-primary img{
    width: 22px; 
    height: 22px;
    margin-top: 4px;
}
.alert-custom-primary div{
    margin-left: 5px;
}
.order-list{
    list-style: none;
    // padding-left: 10px;
}
.course-style{
    position: relative;
    // padding-left: 10px;
    margin-bottom: 8px;
    counter-increment: listNumbering;
}
.course-style h6{ margin-bottom: 0;}
.course-style::after{
    content: "";
}
.course-style::before{
    content: counter(listNumbering);
    position: absolute;
    width: 22px;
    height: 22px;
    border: 3px solid #6d71f9;
    left: -22px;
    top: 0px;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    line-height: 17px;
}
.medal-pagination{
    // background-color: red;
}
.medal-pagination li{
    width: 40px;
    height: 40px;
    text-align: center !important;
    line-height: 40px;
    border-radius: 5px;
}
.medal-pagination .active{
    width: 40px;
    height: 40px;
    background-color: #6D71F9;
    color: white;
    text-align: center !important;
    line-height: 40px;
    border-radius: 5px;
}
.medal-pagination li:before {
    content: "" !important;
}

.trends-flt-btn{
    background: #ebf0ff;
}
.trends-flt-btn:hover{
    background: #6d71f9 !important;
    color:#eee;
    fill: #eee;
}
.dark .trends-filter{
    box-shadow: 2px 4px 6px 2px #000;
}
.trends-filter{
    position: absolute;
    background: white;
    text-align: left;
    color:#000;
    padding: 10px;
    z-index: 1;
    width: 300px;
    min-height: 200px;
    right: 0;
    border-radius: 5px;
    box-shadow: 2px 4px 6px 2px #eee;
    h4{
        font-size: 16px;
    }
    ul{
        list-style: none;
        padding: 0;
        &:nth-child(2){
            li{
                width: 60px;
                height: 21px;
                text-align: center;
                line-height: 21px;
                text-transform: uppercase;
            }
        }
        &:nth-child(4){
           
            li{
                width: 40px;
                height: 21px;
                text-align: center;
                line-height: 21px;
            }
        }
        &:nth-child(6){
          
            li{
                width: 33px;
                height: 21px;
                text-align: center;
                line-height: 21px;
               
            }
        }
        li{
            font-size: 12px;
            background: #EBF0FF;
            margin: 5px;
            display: inline-block;
            border-radius: 2px;
            cursor: pointer;
        }
        
        .active{
            background:#6D71F9 !important;
            color: #eee !important;
        }
    }
   
 
}