.table-action {
    background: none;
    cursor: pointer;

    img {
        height: 34px;
        width: 34px;
    }
}

.pagination-list {
    .page {
        margin: 10px;
        cursor: pointer;
        padding: 5px 15px;
    }

    .active {
        background-color: #6d71f9 !important;
        color: #fff;
        margin: 10px;
        cursor: pointer;
        padding: 5px 15px;
    }
}

.align-j-new {
    margin-bottom: 0px !important;
}

.heading-dash {
    margin-bottom: 0px !important;
}

.buttons-superadmin .buttons {
    padding: 10px 15px 10px 15px !important;
    height: 45px !important;
}

.protocolli-data {
    margin-bottom: 0px;

    .table-responsive {
        margin-bottom: 0px !important;
    }

    table {
        margin-bottom: 0px;
    }

    .search-label {
        margin-bottom: 30px;
    }

    .searchbar {
        margin-left: 0 !important;
        margin-top: 8px;
        position: static;
    }

}

.protocolli-data .react-bootstrap-table-pagination {
    margin: 0;
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;

}

.dark .react-bootstrap-table-pagination {
    background-color: #272848;

    img {
        -webkit-filter: invert(100%) sepia(0) saturate(7500%) hue-rotate(203deg) brightness(200%) contrast(200%);
        filter: invert(100%) sepia(0) saturate(7500%) hue-rotate(203deg) brightness(200%) contrast(200%);
    }
}

.protocollo-space {
    margin-bottom: 100px;
    margin-top: 15px;

}

.protocollol-space {
    margin: 10px 0;
}

.logout-button {
    background: none;
    cursor: pointer !important;
}

#test {
    th {
        text-align: left;
    }

    td {
        text-align: left !important;
    }

    .search_icon {
        padding-left: 14px;
    }
}

.table-action {
    text-align: center;
}

.legenda-text {
    line-height: 33px !important;
    padding: 4px 10px !important;
}



.elimina-popup .modal-content {
    max-width: 640px;
    margin: 0 auto;
    h6 {
        margin-bottom: 33px;
    }

    .scroll-pop-new {
        padding-right: 0px;
        margin-top: 20px;

    }
}

.spinner-container-new {
    transition: .5s;
    background: none;

    .loading-spinner-new {
        background: none;
        width: 25px;
        height: 25px;
        border: 3px solid #f3f3f3;
        border-top: 3px solid #7AB899;
        border-radius: 50%;
        animation: spinner 1.5s linear infinite;
        pointer-events: none;
    }
}

.right-header {
    margin-top: 20px;
    margin-bottom: 25px;
}

.right-header-edit-training {
    margin-bottom: 60px;
}

.graph-tabs-new {
    .nav-tabs {
        border-bottom: 0px solid !important;
        z-index: 9999;

        .nav-link {
            margin-right: 0px !important;
        }

        .nav-link.active {
            border-bottom: 0px solid !important
        }
    }
}

.rieplogo-new {
    margin-bottom: 0px !important;
}

.small-dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;

    .red {
        color: red;
    }

    .green {
        color: rgb(14, 190, 14);
    }

    .yellow {
        color: yellow;
    }

    .blue {
        color: blue;
    }
}

.nova-program .form-label {
    color: var(--nero, #272848);
    font-family: Lexend,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 16px;
}

.registrati-form-new {
    max-width: 100% !important;

    input {
        padding: 4px 15px !important;
    }

    textarea {
        background: #f6f8ff;
        border-radius: 5px;
        border-color: #f6f8ff;
        color: #272848;
        font-weight: 300;
        font-size: 20px;
        padding: 4px 15px;
        height: 100px;
    }

    textarea:focus {
        box-shadow: none !important;
        border-color: #6d71f9 !important;
    }

}

.disable-program-release {
    pointer-events: none;
    opacity: 0.4;
}

.program-status-radio {
    input {
        display: none;
    }

    .radio-white {
        background: #fff;
        display: inline-block;
        width: 100%;
        margin-right: 30px;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        padding-left: 15px;
        line-height: 15px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            display: inline-block;
            width: 15px;
            height: 15px;
            background-color: #fff;
            left: 0;
            border: 1.5px solid #272848;
            border-radius: 100px;
        }
    }

    span {
        margin-left: 5px;
    }

    .active-green {
        &:before {
            background-color: #9ECB61;
            border: 1.5px solid #9ECB61;
        }
    }

    .active-red {
        &:before {
            background-color: #B02418;
            border: 1.5px solid #B02418;
        }
    }

    .active-yellow {
        &:before {
            background-color: #F6BF42;
            border: 1.5px solid #F6BF42;
        }
    }

    select {
        border-radius: 5px;
        background: #f6f8ff;
        border-color: #f6f8ff;
    }
}

.nuovo-programma {
    label {
        margin-bottom: 13px;
        color: var(--nero, #272848);
        font-family: Lexend,sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    textarea {
        color: var(--nero, #272848);
        font-family: Lexend,sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .buttons-superadmin button {
        padding: 20px;
        color: var(--bianco, #FFF);
        font-family: Lexend,sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        height: 64px !important;
        margin-top: 5px;
    }
}


.obbligatorieta-check {
    input {
        display: none;
    }

    .check-radiotype {
        background: #fff;
        display: inline-block;
        margin-right: 30px;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        padding-left: 15px;
        line-height: 35px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            display: inline-block;
            width: 32px;
            height: 32px;
            background-color: #fff;
            left: 0;
            border: 1.5px solid #6D71F9;
            border-radius: 3px;
        }

        span {
            margin-left: 25px;
            color: var(--nero, #272848);
            font-family: Lexend,sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .checked-icon {
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            background-color: #6D71F9;
            background-image: url('../../../../assets/Image/CHECK_ICONA.svg');
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: center;
        }
    }
}

.domanda-right-check {
    .check-radiotype {
        &:before {
            left: inherit !important;
        }
    }
}

.right-header-edit-training {
    display: flex;
    justify-content: space-between;
}

.flex-navtabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.opacity0 {
    opacity: 0;
}

.push-table {
    margin-top: -50px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .graph-tabs-new #search-bar-0 {
        margin-right: 0px;
    }
}

@media (max-width: 767px) {
 
    .program-document {
         span {
            color: var(--primarioviola, #6D71F9);
            font-family: Lexend,sans-serif;
            font-size: 14px !important;
            font-style: normal;
            font-weight: 600 !important;
            line-height: 124.296%;
        }
    }
    .description-document {

        .icon {
            margin: 0px 15px;
        }

        .icon-image {
            margin: -1px 15px;
            position: relative;
            cursor: pointer;

            .play-button {
                

                img {
                    height: 50px;
                    width: 50px;
                }

               
            }
            >img {
                width: 120px!important;
                height: 100px;
            }

        }
        .text {
            font-family: Lexend,sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 124.296%;
        }

      
    }
    .super-search-top {
        margin-top: 15px;
    }

    .flex-navtabs {
        display: block;
    }

    .right-header-edit-training {
        margin-top: 0px;
        margin-bottom: 15px;
        display: block;
        text-align: left;
    }

    .buttons-superadmin {
        margin-top: 15px;

        button {
            margin-bottom: 15px;
        }
    }
    .play-btn{
        left: 30%!important;
    }
}

.pagination-buttons {
    margin: "10px";
    cursor: "pointer";
}

.user-list-box {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    background-color: white;
    padding: 10px;
    margin-bottom: 40px;

    div {
        min-width: 200px;
        padding: 5px 0px;
        text-align: left;
    }
}

.obbligatorieta-check-new {
    input {
        display: none;
    }

    .check-radiotype {
        background: #fff;
        display: inline-block;
        margin-right: 30px;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        padding-left: 15px;
        line-height: 35px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            display: inline-block;
            width: 32px;
            height: 32px;
            background-color: #fff;
            left: -10px;
            top: -10px;
            border: 1.5px solid #6D71F9;
            border-radius: 3px;
        }

        span {
            margin-left: 25px;
            color: var(--nero, #272848);
            font-family: Lexend,sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .checked-icon {
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            background-color: #6D71F9;
            background-image: url('../../../../assets/Image/CHECK_ICONA.svg');
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: center;
        }
    }
}

.selectuser-pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        padding: 5px 10px;

        ::before {
            content: "" !important;
        }
    }

}

.sa-program-pagination ul li::before {
    content: '' !important;
}

.sa-program-pagination ul li {
    width: 34px;
    height: 34px;
    border-radius: 5px;
    text-align: center !important;
    color: var(--nero, #272848);
    font-family: Lexend,sans-serif;
    font-size: 15.343px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px !important;
    margin-right: 10px !important;
    cursor: pointer;
}

.sa-program-pagination ul li.selected a {
    color: #FFF !important;
}
.selected {
    background-color: #6d71f9;
}

.search-bar-new {
    border-radius: 5px;
    margin-right: 11px;
    width: 200px !important;
    height: 36px;
    background-color: rgba(109, 113, 249, 0.1019607843);
    padding: 15px;
    text-align: left;
    font-size: 14px;
    border: none;
    padding-left: 38px;
}

.search_icon-new {
    position: absolute;
    top: 11px;
    left: 13px;
}

.next-pagination {
    background-image: url("../../../../assets/Image/NEXTICONA.svg");
    height: 100px;
    width: 50px;
    background-color: #6D71F9;
}

.hight-space {
    min-height: 80px !important;
}

.test-choice {
    background: #6D71F91A;
    border-radius: 4px;
    margin-left: 8px;
    color: #6D71F9;
    font-weight: 500;
    cursor: pointer;
}

.modifica-programi-popup .modal-dialog {
    min-width: 956px;
}

.modifica-programi-popup .accordion {
    padding-left: 0px;
}

.bg-light-purple {
    background-color: #f0f1fe !important;
    border-radius: 5px 5px 0px 0px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.full-cover-bordertable {
    border-radius: 5px;
    border: 1px solid #f0f1fe;
}

.bg-light-purple .d-faqflex {
    padding: 22px 20px;
    color: #6D71F9;
    font-family: Lexend,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 134.296%;
}

.scroll-pop-new .accordion .bg-light-purple:after {
    content: url('../../../../assets/Image/arrow-up-side.svg');
    position: absolute;
    top: 14px;
    right: 10px;
    transform: rotate(0deg);
}

.scroll-pop-new .accordion .bg-light-purple.collapsed:after {
    content: url('../../../../assets/Image/arrow-up-side.svg');
    position: absolute;
    top: 14px;
    right: 10px;
    transform: rotate(180deg);
}

.modifica-programi-popup .card-body {
    padding-left: 20px !important;

    .select-iniza {
        width: 171px;
        height: 36px;

        select {
            width: 100%;
            min-height: 36px;
            padding: 9px 12px;
            color: #272848;
            font-family: Lexend,sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .flex-inizia {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .ripeti {
            width: 120px;
            height: 36px;
            padding: 0;
            box-shadow: 0px 10px 28px 0px rgba(109, 113, 249, 0.20);
        }
    }

    .right-super-select-arrow {
        background-position: 146px 15px;
    }

    .table-Percorsi {
        margin-top: 16px;
        width: 100%;

        th {
            color: #272848;
            font-family: Lexend,sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 134.296%;
            margin-bottom: 16px;
            padding-bottom: 15px;
        }

        td {
            color: #272848;
            font-family: Lexend,sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 134.296%;
            text-transform: capitalize;
            padding-bottom: 30px;

            &:nth-child(2) {
                width: 60%;
            }
        }

        .vertical-align-check {
            position: relative;
            vertical-align: text-bottom;

            &::before {
                content: "";
                position: absolute;
                width: 1px;
                height: 42px;
                border: 1px dashed #6D71F9;
                bottom: 0;
                left: 7px;

                &:last-child::before {
                    border: none;
                }
            }

            input[type="checkbox"] {
                border: 1px solid #6D71F9;
                width: 16px;
                height: 16px;
                accent-color: #6D71F9;
            }
        }
    }
}


.button-cursor {
    cursor: pointer;
}

.document-section {
    background-color: white;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 15px 10px;

    .description-document {

        .icon {
            margin: 0px 15px;
        }

        .icon-image {
            margin: -1px 15px;
            position: relative;
            cursor: pointer;

            .play-button {
                .link {
                    filter: brightness(0) invert(1);
                }

                img {
                    height: 50px;
                    width: 50px;
                }

                position: absolute;
                left: 52%;
                transform: translate(-50%, 55%);
            }
            >img {
                width: 160px;
                height: 100px;
            }

        }

        .text {
            font-family: Lexend,sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 134.296%;

            div {
                min-height: 50px;
                white-space: pre-line;
                position: relative;

                button {
                    color: #6D71F9;
                    background: none;
                    position: absolute;
                    bottom: -10px;
                    right: 0;
                    cursor: pointer;
                }
            }

            span {
                font-weight: 400;
            }
        }
    }

    .program-document {
        text-align: left;
        border-left: 1px solid rgba(109, 113, 249, 0.3);
        padding-left: 10px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;

        span {
            color: var(--primarioviola, #6D71F9);
            font-family: Lexend,sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 134.296%;
        }
    }
}

.document-program-details {
    font-family: Lexend,sans-serif;
    line-height: 134.296%;
    background-color: #e4e5fd;
    border-radius: 5px;
    padding: 13px;
    font-style: normal;
    margin: 14px 0px;

    .name {
        font-size: 16px;
        font-weight: 700;
    }

    .description {
        font-size: 14px;
        font-weight: 400;
    }
}

.wrapperClassName {
    display: flex;
    flex-direction: column-reverse;
    border-radius: 5px;
    background-color: #f6f8ff;

    ul {
        margin: 16px 0 !important;
        padding: 0 !important;
    }

    ul li::before {
        content: '' !important;
        margin-left: 0px;
        display: block !important;
    }
}


.editorClassName {
    padding: 8px 15px;

    :focus {
        box-shadow: none !important;
        border-color: #6d71f9 !important;
    }
}

.toolbarClassName {
    justify-content: flex-end !important;
    margin-bottom: 1px !important;
    margin-left: auto;

    >div {
        border-left: 1px solid rgba(109, 113, 249, 0.3);
    }
}

.select-border {
    background: #f6f8ff;
}

.select-border:focus {
    background-color: #f6f8ff !important;
    box-shadow: none;
}



.totali {
    background: url('../../../../assets/Image/CHEVRON_ICONA.svg'), #6d71f91a !important;
    background-repeat: no-repeat !important;
    background-position: 129px 14px !important;
    padding: 0px 25px 0 9px !important;
    height: 36px;
    margin-bottom: 21px;
    width: 171px;
}

.durate-box {
    max-width: 100%;
    text-align: left;

    h2 {
        color: #272848;
        font-family: Lexend,sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .durate-mediabox {
        padding: 16px 17px;
        border-radius: 5px;
        background: var(--bianco, #FFF);
        box-shadow: 0px 4px 16px 0px rgba(234, 238, 255, 0.60);
        margin-bottom: 20px;

        h2 {
            color: #272848;
            font-family: Lexend,sans-serif;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            margin-bottom: 0;
        }

        p {
            color: #272848;
            font-family: Lexend,sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: capitalize;
            margin-bottom: 0;
        }
    }

    .durate-paddleft-to-right {
        padding: 5px 17px;

        p {
            font-size: 10px;
        }
    }

    .ripeti {
        height: 27px !important;
        line-height: 0;
        padding: 0px 6px !important;
        width: max-content;
    }
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #6d71f9 !important;
    font-family: Lexend,sans-serif !important;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    text-transform: capitalize;
    dominant-baseline: central !important;
}

.centeralign-Progressbar {
    margin: 0px auto;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: #c7c8f5 !important;
    opacity: 1 !important;
}

.total-count-person {
    text-align: right;
    width: 100%;
    color: var(--nero, #272848);
    font-family: Lexend,sans-serif;
    font-size: 15.343px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px !important;
}

.concluso-radio {
    display: flex !important;
    color: var(--nero, #272848);
    font-family: Lexend,sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 23px !important;
    padding-left: 25px !important;

    &:before {
        width: 20px !important;
        height: 20px !important;
        opacity: 0.30000001192092896;
    }

}


.active-purpule-radio {
    &:before {
        background-color: #6D71F9 !important;
        border: 1.5px solid #6D71F9 !important;
        opacity: 1 !important;

    }
}

.inactive-purpule-radio {
    &:before {
        background-color: #c7c8f5 !important;
        border: 1.5px solid #c7c8f5 !important;
        opacity: 1 !important;

    }
}

.superato-right-space {
    margin-right: 15px;
    width: 100%;
}

.total-superto {
    color: var(--bianco, #FFF);
    font-family: Lexend,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.status-bg-color {
    background-color: #9ECB61;
}

.text-style-progress {
    background-color: green;
}

.domanda {
    input {
        background: #f6f8ff !important;
        color: var(--nero, #272848);
        font-family: Lexend,sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 134.296%;
        padding: 20px 15px !important;
        border: none;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    textarea {
        resize: none;
        height: 70px;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

.text-color-save {
    float: right;
    margin: 5px;
    color: #6D71F9;
    cursor: pointer;
}

.place-opacity {
    padding: 12px 14px !important;
    font-size: 16px !important;
    font-weight: 400 !important;

    &::placeholder {
        opacity: 1 !important;
        color: #9197a3 !important;
    }
}

.placeholder-design {
    &::placeholder {
        opacity: 1 !important;
        color: #9197a3 !important;
    }
}


.blockType-dropdown-class {
    max-height: 10px;
    display: inline-table;

    li::before {
        content: '' !important;
    }
}

.back-button {
    background: none;
    margin-right: 10px;
    cursor: pointer;

    img {
        padding-bottom: 6px;
    }
}

.desc-normal {
    font-weight: 400 !important;
}

.que-font {
    font-size: 20px !important;
}

.no-of-que {
    height: 50px;
    width: 200px;
}

.test-desc {
    resize: none;
    height: 110px;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.no-of-percentage {
    height: 50px;
    width: 710px !important;
}

.program-user-div {
    background-color: white;
    border-radius: 5px;
}

.program-user-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 5px 10px;

    .program-user {
        width: 33%;
        text-align: start;
        padding: 5px 0px;

        .hr-program-user {
            margin-top: 5px;
            margin-bottom: 5px;
            border: 0;
            border-top: 1px solid #6d71f9;
            width: 300%;
        }
    }
}

.percentage-sign {
    position: absolute;
    right: 50px;
}

.model-header-adjust {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.close-btn-pr {
    margin: 0 !important;
    padding: 0 !important;
}

.carica-nuovo-heading {
    h3 {
        color: #272848;
    }
}

.react-player .player .vp-video-wrapper video {
    border-radius: 10px !important;
}

#vp-preview {
    border-radius: 10px !important;
}

video {
    border-radius: 10px !important;
}

.LabeledButton_module_box__e0f241d2 {
    display: none !important;
}

.vp-sidedock {
    display: none !important;
}

.show-video-title {
    font-family: Lexend,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 3.94px;
}

.check-radiotype:before {
    width: 16px;
    height: 16px;
}

.checked-icon:before {
    background-size: 11px;
}

.pl20 {
    padding-left: 0px;
    color: #272848;
    font-family: Lexend,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.domanda-right-check {
    margin-bottom: 11px;
}


.learning-popup-size {
    .modal-content {
        right: -79px;
    }

    line-height: 134.296%;
    margin: 10px 16px;
}

.show-video-description {
    font-family: Lexend,sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 134.296%;
    margin: 10px 16px;
}

.load-new-heading {
    h3 {
        color: #272848;
        font-family: Lexend,sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 5px;
    }
}


.mandatory-check.question-right-check label {
    margin-bottom: 0;
    margin-left: 4px;
}


.question-right-check {
    position: relative;
    margin-bottom: 5px;
}

.check-radiotype input {
    opacity: 0;
    margin: -14px;
    position: absolute;
    width: 14px;
    height: 17px;
}

.question-right-check:before {
    content: "";
    position: relative;
    top: 3px;
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 1.5px solid #6D71F9;
    border-radius: 3px;
}

.question-right-check .checked-icon:before {
    content: "";
    background-color: #6D71F9;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
    width: 15px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 3px;
    border-radius: 3px;
}

.true-btn {
    background-color: #6d71f9 !important;
    padding: 5px 20px 5px 20px;
    border-color: #6d71f9 !important;
    border-radius: 5px !important;
    font-size: 20px;
    color: #fff !important;
    letter-spacing: 0.5px;
    font-weight: 500;
    cursor: pointer;
}

.false-btn {
    background-color: #cccc;
    height: 40px;
    padding: 5px 20px 5px 20px;
    font-size: 20px;
    border-radius: 5px;
    color: #fff !important;
    letter-spacing: 0.5px;
    font-weight: 500;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.url-error-msg {
    color: red;
}

.dropdown-container {
    width: 100%;

    .css-13cymwt-control {
        background-color: #f6f8ff;
        border: none;
    }

    .css-1jqq78o-placeholder {
        color: #9197a3;
    }

    .css-1fdsijx-ValueContainer {
        padding: 2px 15px;
    }

    .css-1p3m7a8-multiValue {
        border-radius: 3px;
        margin: 3px;
        padding: 2px 0px;
        background-color: #e3e4fd;
    }

    .css-12a83d4-MultiValueRemove {
        color: #6d71f9;
    }

    .css-1u9des2-indicatorSeparator {
        display: none;
    }

    input {
        padding: 0px !important;
        margin: 5px 0px !important;
    }
}

.background {
    background-color: #EBF0FF;
    width: 100%;
    height: 100%;
    padding: 15px;
    padding-left: 50px;
}

.select-number {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background: var(--BIANCO, #FFF);
    border: none;
    box-shadow: 0px 4px 16px 0px rgba(234, 238, 255, 0.60);
}

.date-input {
    width: 100% !important;
    height: 40px !important;
    border-radius: 5px;
    background: var(--BIANCO, #FFF);
    border: none;
    box-shadow: 0px 4px 16px 0px rgba(234, 238, 255, 0.60);
    position: relative;
    cursor: pointer;

    input {
        border: none;
        width: 100% !important;
        margin: 8px 5px;
        cursor: pointer;
    }
}

.react-datepicker-wrapper {
    width: 98%;
    position: relative;
}

.brainwelness-edit-date {
    position: absolute;
    right: -5px;
    height: 35px;
    cursor: pointer;
    margin-top: 3px;
    z-index: 0;
    top: 50%;
    transform: translateY(-50%);
}

.img-span {
    width: 50px;
    height: 30px;
}

.edit-training-date {
    display: flex;
    flex-direction: column;
    height: 650px;
    justify-content: space-between;
}

.save-btn {
    display: flex;
    width: 124px;
    padding: 19px 70px 20px 70px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: var(--PRIMARIO_VIOLA, #6D71F9);
    color: #fff;
    color: var(--BIANCO, #FFF);
    font-family: Lexend,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
}

.training-heading {
    color: #6D71F9;
    font-family: Lexend,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.heading-line {
    width: 109px;
    height: 3px;
    background: #6D71F9;
}

.progress {
    height: 7px !important;
}

.progress-bar-color {
    background-color: #9ECB61 !important;
}

.lock-card {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.audio-close {
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
}

.audio-close-btn {
    display: flex;
    justify-content: end;
    margin-right: 5px;
    cursor: pointer;
}


#content-select {
    input {
        padding: 4px 0px !important;
    }

    .dropdown-block {
        display: flex;
        align-items: flex-start;
    }

    .video-margin {
        margin-top: 1px;
    }

    .document-margin {
        margin-top: 3px;
    }

    .dropdown-label {
        margin-left: 8px;
    }
}

.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.percorso-header-new::after {
    top: 20px !important;
}

.searchbar-sa {
    #search-bar-0 {
        margin-right: -8px !important;
        margin-top: 1px !important;
    }
}

.searchbar-content {
    #search-bar-0 {
        margin-right: 0px !important;
    }
}

.react-pdf__Document {
    overflow-y: auto;
    overflow-x: auto;
    width: 720px;
}

.edit-date {
    position: absolute;
    top: 10px;
    right: 10px;
}

.react-pdf__Document::-webkit-scrollbar {
    width: 6px;
    height: 7px;
    background-color: transparent;
    margin-bottom: 6px !important;
}

.react-pdf__Document::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #6d71f9;
    border-radius: 50px;
}

//added for protocal add
.date-input-new,
.week-input-new {
    .react-datepicker-wrapper {
        width: 100%;
    }

    .arrow_down1-new {
        position: relative;

        label {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    select {
        background: #fff;
        padding: 16px;
        border-radius: 5px;
        border-color: #fff;
        font-weight: 400;
        font-size: 20px;
        width: 100%;
    }

    .react-datepicker__input-container {
        input {
            width: 100%;
            border: none;
        }
    }

    h4 {
        font-size: 18px;
        color: #272848;
        font-weight: 700;
    }

    .date-input {
        position: relative;
    }

    span {
        position: absolute;
        top: 10px;
        right: 0px;
    }
}

.company-details-form {
    padding-right: 0px !important;

    label {
        font-size: 18px !important;
    }
}

.circle-profile-new {
    height: 173px !important;
    width: 173px !important;
    object-fit: none !important;
}

.select-color {
    margin-right: 10px;
    height: 25px;
    width: 32px;
    border-radius: 50%;
    margin-top: 23px;

    .sketch-picker {
        position: absolute;
        top: 70px;
        left: 0;
        z-index: 999;
    }
}

.domanda-right-check-new {
    .check-radiotype {
        padding-left: 0px !important;

        &::before {
            top: 3px;
            width: 20px !important;
            height: 20px !important;
        }
    }
}

.color-box-margin {
    margin-top: 51px;
    margin-bottom: 35px;
}

#pdf-controls {
    background-color: #fff !important;
}

.dark {
    .collapse-dark {
        background-color: #272848 !important;

        img {
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(203deg) brightness(200%) contrast(200%);
        }
    }

    .show-video-title,
    .show-video-description {
        margin-top: 10px;
        color: #fff;
        text-align: start;
    }

    .all-test-button {
        span {
            color: #fff;
        }
    }

    .user-count {
        color: #fff;
    }

    .logout-button {
        color: #fff;

        img {
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(203deg) brightness(200%) contrast(200%);
        }
    }

    .sa-program-pagination {
        li {
            a {
                color: #fff;

                img {
                    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(203deg) brightness(200%) contrast(200%);
                }
            }
        }
    }

    .document-section {
        background-color: #272848;
        color: #fff;

        .description-document-new {
            img {
                filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(203deg) brightness(200%) contrast(200%);
            }
        }
    }

    .margin-left20,
    .course-name,
    .percorso-heading {
        color: #fff;
    }

    .percorso-header-new,
    .percorso-header {
        &::after {
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(203deg) brightness(200%) contrast(200%);
        }
    }

    .Stato {
        color: #fff;

        img {
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(203deg) brightness(200%) contrast(200%);
        }
    }

    .program-user-div {
        background-color: #272848;
        color: #fff;
    }

    .assegnatari span {
        color: #fff;
    }

    .right-super-select-arrow {
        color: #fff;

        option {
            background-color: #272848;
        }
    }

    .durate-box {
        h2 {
            color: #fff;
        }

        .durate-mediabox {
            box-shadow: none;
            background-color: #272848;

            h2,
            p {
                color: #fff;
            }
        }

        .active-purpule-radio,
        .concluso-radio {
            background-color: #272848;
            color: #fff;
        }
    }
}

.title-word-wrap {
    word-break: break-word;
}

.new-program-name {
    border-collapse: separate;
    border-spacing: 0 10px;
}

.nuovo-programma {
    input {
        font-size: 16px !important;
        font-weight: 400 !important;
    }

    span {
        font-weight: 500;
    }
}

.user-admin-margin-box {
    margin-bottom: 35px;
}

.btn-save-cancel {
    float: right;
}

.react-datepicker__navigation {
    margin-top: -3px !important;
}

.react-datepicker__day-names {
    margin-top: 24px;
}

.react-datepicker__month-read-view--selected-month {

    border-radius: 5px;
    background-color: #6d71f9;
    padding: 6px;
    /* border: 1px solid; */
    color: #fff;
    width: 99px;
    text-align: left;
    font-size: 12px;

}

.react-datepicker__month-read-view {
    margin-left: 80px;
    margin-bottom: 10px;
    width: 22px;
    height: 0;
    text-align: center;

    .react-datepicker__month-read-view--down-arrow {
        border-color: #fff;
        border-width: 2px 2px 0 0;
    }
}

.react-datepicker__month-read-view--down-arrow {
    width: 8px;
    height: 8px;
    z-index: 99;
    top: 20px !important;
    position: relative !important;
    left: 5px;
}

.react-datepicker__year-read-view--selected-year {
    background-color: #6d71f9;
    padding: 6px;
    border: 1px solid;
    color: #fff;
    width: 69px;
    font-size: 12px;
    text-align: left;
    border-radius: 5px;
}

.react-datepicker__year-read-view--down-arrow {
    width: 8px;
    height: 8px;
    z-index: 99;
    top: 20px !important;
    position: relative !important;
    left: -7px;
}

.react-datepicker__month-dropdown-container--scroll {
    margin: 0 22px;
}

.react-datepicker__year-read-view {
    margin-left: 30px;
    margin-bottom: 3px;

    .react-datepicker__year-read-view--down-arrow {
        border-color: #fff;
        border-width: 2px 2px 0 0;
    }
}

.react-datepicker__year-dropdown-container .react-datepicker__year-dropdown-container--scroll {
    margin-left: 10px;
    margin-bottom: 10px;
}

.react-datepicker__month-option--selected {
    display: none;
}

.react-datepicker__year-option--selected {
    display: none;
}

.react-datepicker__year-dropdown {
    margin-left: 70px;
}

.react-datepicker__month-dropdown {
    margin-left: -30px;

}

.text-alert-status {
    width: 160px;
    text-align: center;
}
