.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a {
  text-decoration: none;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 100px;
  height: 100px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #6d71f9; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  position: absolute;
  top: 45%;
  left: 45%;
  pointer-events: none;
}
.spinner-container {
  z-index: 999999999;
  position: fixed;
  height: 100vh;
  width: 100%;
  background: rgba(255, 255, 255, 0.60);
  top: 0;
  left: 0;
  transition: .5s;
  /* pointer-events: none; */
}